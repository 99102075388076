@use 'sass:map';
@use 'sass:meta';
@use '@angular/material' as mat;

@use '@soleran/ngx-slatejs';

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($color-config);
	}
}

@mixin color($color-config) {
	$primary-palette: map.get($color-config, primary);
	$accent-palette: map.get($color-config, accent);

	:root {
		//Table Plugin
		--sol-table-toolbar-item-preview-border-color: #{mat.m2-get-color-from-palette(
				$primary-palette,
				200
			)};
		--sol-table-element-column-resizer-border-color: #{mat.m2-get-color-from-palette(
				$accent-palette,
				200
			)};
		//Image Plugin
		--sol-image-toolbar-item-file-upload-highlight-color: #{mat.m2-get-color-from-palette(
				$primary-palette,
				600
			)};
	}
}
