@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;

.sol-hierarchy {
  &-horizontal {
    padding: 1em 0;
  }

  &-vertical {
    padding: 0 1em;
  }

  &-border {
    border: 1px solid darkgrey;
  }

  &-box {
    border-radius: 8px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }

  &-connector {
    &-horizontal {
      width: 1em;
    }

    &-vertical {
      height: 1em;
    }
  }
}

.sol-hierarchy {
  &-image {
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    background-color: white;
    border-radius: 50%;
    padding: 0.25em;
  }

  &-name {
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }

  &-title {
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  }
}

app-dataset-selector .mat-drawer-inner-container {
  display: flex;
}

sol-report-builder mat-stepper .mat-horizontal-stepper-header-container {
  @include mat.elevation(2);
  z-index: 2;
}

sol-report-builder mat-stepper .mat-horizontal-content-container {
  padding: 0;
}

.cdk-overlay-container {
  mat-optgroup[sol-sort-card-group-option].sticky {
    span.mat-mdc-optgroup-label {
      margin-top: -1;
      position: sticky;
      z-index: 1;
      top: 0;
      background-color: var(--mat-autocomplete-background-color);
      border-top: solid 1px hsla(0, 0%, 0%, 0.122);
      border-bottom: solid 1px hsla(0, 0%, 0%, 0.122);
      filter: brightness(0.9);
      font-weight: 500;
      // @include mat.elevation(2);
    }
  }
  div.mat-mdc-select-panel {
    padding-top: 0;
  }
}

// .sol-directory-tree .sdt-content-node .sdt-mat-symbol {
//   transform: rotate(270deg);
// }
[sdi-type="DATASET"] {
  .sdi-mat-symbol,
  .sdl-mat-symbol,
  .sdt-mat-symbol {
    rotate: 270deg;
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($color-config);
  }
}

@mixin color($color-config) {
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);
  $warn-palette: map.get($color-config, warn);
  $background-palette: map.get($color-config, background);
  $background-color: map.get($background-palette, background);
  $background-light-contrast: mat.m2-get-contrast-color-from-palette(
    $accent-palette,
    A700
  );
  // $background-light-contrast: mat.get-color-from-palette($primary-palette, 50);
  $primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
  $primary-lightest: mat.m2-get-color-from-palette($primary-palette, 50);
  $accent-color: mat.m2-get-color-from-palette($accent-palette, 500);
  $accent-lightest: mat.m2-get-color-from-palette($accent-palette, 50);
  $warn-color: mat.m2-get-color-from-palette($warn-palette, 500);

  :root {
    --sol-background-color: #{$background-color};
    --sol-background-contrast: #{$background-light-contrast};
    --sol-primary-color: #{$primary-color};
    --sol-primary-lightest: #{$primary-lightest};
    --sol-accent-color: #{$accent-color};
    --sol-accent-lightest: #{$accent-lightest};
    --sol-warn-color: #{$warn-color};
  }

  .mat-bottom-sheet-container {
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    max-height: min(90vh) !important;
  }

  sol-builder-form sol-group-form {
    background-color: var(--sol-background-contrast, blue);
    @include mat.elevation(2);
  }
}
