sol-report-builder mat-stepper .mat-horizontal-stepper-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
}

sol-report-builder mat-stepper .mat-horizontal-content-container {
	padding: 0;
	height: 100%;
}

sol-report-builder mat-stepper .mat-horizontal-stepper-content {
	height: 100%;
	display: flex;
	flex-direction: column;
}

sol-report-builder mat-tab-group .mat-mdc-tab-body-wrapper {
	flex: 1;
}
.cdk-overlay-container {
	mat-optgroup[sol-sort-card-group-option].sticky {
		span.mat-mdc-optgroup-label {
			margin-top: -1;
			position: sticky;
			z-index: 1;
			top: 0;
			background-color: var(--mat-autocomplete-background-color);
			border-top: solid 1px hsla(0, 0%, 0%, 0.122);
			border-bottom: solid 1px hsla(0, 0%, 0%, 0.122);
			filter: brightness(0.9);
			font-weight: 500;
			// @include mat.elevation(2);
		}
	}
	div.mat-mdc-select-panel {
		padding-top: 0;
	}
}


.cdk-overlay-container {
	mat-optgroup[sol-group-option].sticky {
	  span.mat-mdc-optgroup-label {
		position: sticky;
		z-index: 1;
		top: 0;
		background-color: rgb(255, 255, 255);
		border-top: solid 1px hsla(0, 0%, 0%, 0.122);
		border-bottom: solid 1px hsla(0, 0%, 0%, 0.122);
		filter: brightness(0.9);
		font-weight: 500;
		// @include mat.elevation(2);
	  }
	}
	div.mat-mdc-select-panel {
	  padding-top: 0;
	}
  }
  

.overflow-scroll-gradient {
	position: relative;
}
.overflow-scroll-gradient::before {
	content: '';
	position: absolute;
	top: 0;
	width: 100%;
	height: 25px;
	background: linear-gradient(
		white,
		rgba(255, 255, 255, 0.001)
	); /* transparent keyword is broken in Safari */
}
.overflow-scroll-gradient::after {
	content: '';
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 25px;
	background: linear-gradient(
		rgba(255, 255, 255, 0.001),
		white
	); /* transparent keyword is broken in Safari */
}
.overflow-scroll-gradient__scroller {
	overflow-y: scroll;
	background: white;
	width: 300px;
	height: 150px;
	padding: 15px 0;
	line-height: 1.2;
	text-align: center;
}