@use 'sass:map';
@use 'sass:meta';
@use '@angular/material' as mat;

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($color-config);
	}
}

@mixin color($color-config) {
	$primary-palette: map.get($color-config, primary);

	//Directory Item
	.sdi-default-preview {
		--sol-directory-item-preview-background-color: #{mat.m2-get-color-from-palette(
				$primary-palette,
				50
			)};
	}

	.sol-directory-breadcrumb {
		--sol-directory-breadcrumb-edge-highlight-color: #{mat.m2-get-color-from-palette(
				$primary-palette,
				300
			)};
	}

	.sol-directory-list {
		--sol-directory-list-item-highlight-color: #{mat.m2-get-color-from-palette($primary-palette, 100)};
	}

	.sol-directory-tree {
		--sol-directory-tree-highlight-color: #{mat.m2-get-color-from-palette($primary-palette, 200)};
		--sol-directory-tree-edge-highlight-color: #{mat.m2-get-color-from-palette($primary-palette, 300)};
	}
}
