// SOL-CHIP-GRID

mat-chip-grid[sol-chip-grid].mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      flex: 1;
    }
    .mdc-evolution-chip {
      width: 100%;
    }
  }
  
  // SOL-LIST - SELECTION
  
  mat-selection-list[sol-list].mat-dense-3
    .mdc-list-item.mdc-list-item--with-one-line {
    height: fit-content;
    .mdc-checkbox {
      padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 5)
        calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 2);
      .mdc-checkbox__background {
        top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 5);
      }
    }
    .mdc-list-item__end {
      display: flex;
      .mdc-radio {
        padding: calc((var(--mdc-radio-state-layer-size, 40px) - 20px) / 5);
      }
    }
  }
  mat-selection-list[sol-list].mat-dense-2
    .mdc-list-item.mdc-list-item--with-one-line {
    height: fit-content;
    .mdc-checkbox {
      padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 4)
        calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 2);
      .mdc-checkbox__background {
        top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 4);
      }
    }
    .mdc-list-item__end {
      display: flex;
      .mdc-radio {
        padding: calc((var(--mdc-radio-state-layer-size, 40px) - 20px) / 4);
      }
    }
  }
  mat-selection-list[sol-list].mat-dense-1
    .mdc-list-item.mdc-list-item--with-one-line {
    height: fit-content;
    .mdc-checkbox {
      padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 3)
        calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 2);
      .mdc-checkbox__background {
        top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 18px) / 3);
      }
    }
    .mdc-list-item__end {
      display: flex;
      .mdc-radio {
        padding: calc((var(--mdc-radio-state-layer-size, 40px) - 20px) / 3);
      }
    }
  }
  
  // SOL-LIST
  
  mat-list[sol-list].mat-dense-3 .mdc-list-item.mdc-list-item--with-one-line {
    height: calc(var(--mdc-list-list-item-one-line-container-height) * 0.45);
  }
  mat-list[sol-list].mat-dense-2 .mdc-list-item.mdc-list-item--with-one-line {
    height: calc(var(--mdc-list-list-item-one-line-container-height) * 0.6);
  }
  mat-list[sol-list].mat-dense-1 .mdc-list-item.mdc-list-item--with-one-line {
    height: calc(var(--mdc-list-list-item-one-line-container-height) * 0.8);
  }
  
  // FORM-FIELDS
  
  mat-form-field.mat-dense-2
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    // &:not(:has([type="number"])) {
    padding-top: 20px;
    padding-bottom: 4px;
    // }
    .mat-mdc-floating-label {
      display: block;
    }
  }
  mat-form-field.mat-dense-3
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined)
    .mat-mdc-form-field-infix {
    // &:not(:has([type="number"])) {
    padding-top: 18px;
    padding-bottom: 2px;
    // }
    .mat-mdc-floating-label {
      display: block;
    }
  }
  
  sol-formula-config-form slate-editable p[sol-element].sol-paragraph-element {
    margin: 0;
  }