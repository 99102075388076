$colors: (
  decision: #c4da97,
  notification: #a6cae9,
  data: #de6593,
  time-delay: #e27e3a,
  stop: #e08585,
  form-modification: aquamarine,
);

@each $name, $color in $colors {
  .sol-wf-fg-#{$name}-color {
    color: $color;
  }
  .sol-wf-bg-#{$name}-color {
    background-color: $color;
  }
}
.sol-hierarchy-box:has(sol-add-node) {
  overflow: visible !important;
}
.sol-hierarchy-box sol-add-node mat-card {
  border-radius: 8px !important;
  width: 100% !important;
}

.sol-fx-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.sol-ngx-workflow-builder {
  display: flex;
}
@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 150, 255, 0.8);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px rgba(0, 150, 255, 0.6);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 10px rgba(0, 150, 255, 0.8);
  }
}

.active-node {
  animation: pulse 1.5s infinite ease-in-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}
