@use 'sass:map';
@use '@angular/material' as mat;
@use '@soleran/ngx-auth-utility' as auth-utility;
@use '@soleran/ngx-common' as common;
@use '@soleran/ngx-control' as control;
@use '@soleran/ngx-directory' as directory;
@use '@soleran/ngx-expression' as expression;
@use '@soleran/ngx-layout-utility' as layout;
@use '@soleran/ngx-report-builder' as report-builder;
@use '@soleran/ngx-report-utility' as report;
@use '@soleran/ngx-rich-text-editor' as rich-text-editor;

html {
	--faded-default-color: #000000b5;

	//Google Symbols
	.material-symbols-outlined {
		font-variation-settings:
			'FILL' 1,
			'wght' 400,
			'GRAD' 0,
			'opsz' 48;

		&.no-fill {
			font-variation-settings:
				'FILL' 0,
				'wght' 400,
				'GRAD' 0,
				'opsz' 48;
		}
	}

	.icon-faded,
	.button-faded {
		--mdc-icon-button-icon-color: var(--faded-default-color);
		--mat-icon-color: var(--faded-default-color);
		color: var(--faded-default-color);
	}
}

$sol-brand-primary: (
	50: #fde6e6,
	100: #fac0c1,
	200: #f79697,
	300: #f36c6d,
	400: #f14d4e,
	500: #ee2d2f,
	600: #ec282a,
	700: #e92223,
	800: #e71c1d,
	900: #e21112,
	A100: #ffffff,
	A200: #ffdcdc,
	A400: #ffa9aa,
	A700: #ff9090,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$sol-brand-secondary: (
	50: #e4ebf1,
	100: #bdcddc,
	200: #91acc5,
	300: #648aad,
	400: #43719c,
	500: #22588a,
	600: #1e5082,
	700: #194777,
	800: #143d6d,
	900: #0c2d5a,
	A100: #8fb8ff,
	A200: #5c98ff,
	A400: #2978ff,
	A700: #0f68ff,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #ffffff,
		A700: #ffffff
	)
);

$primary-palette: mat.m2-define-palette($sol-brand-primary);
$accent-palette: mat.m2-define-palette($sol-brand-secondary, A400);
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);

$light-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $primary-palette,
			accent: $accent-palette,
			warn: $warn-palette
		),
		typography: mat.m2-define-typography-config(),
		density: 0
	)
);

@include mat.core();
@include mat.all-component-typographies();
@include mat.all-component-themes($light-theme);

//Densities - 'node_modules/@angular/material/_index.scss'
@include mat.expansion-density(-1);
@include mat.form-field-density(-1);
@include mat.paginator-density(-1);
@include mat.stepper-density(-2);
@include mat.table-density(-2);
@include mat.tabs-density(-1);
@include mat.toolbar-density(-2);

//Libraries
@include auth-utility.theme($light-theme);
@include common.theme($light-theme);
@include control.theme($light-theme);
@include directory.theme($light-theme);
@include expression.theme($light-theme);
@include layout.theme($light-theme);
@include report-builder.theme($light-theme);
@include report.theme($light-theme);
@include rich-text-editor.theme($light-theme);

@function get-color($color: null, $hue: null) {
	$light-color-config: mat.m2-get-color-config($light-theme);
	$light-color-palette: map.get($light-color-config, $color);

	@return mat.m2-get-color-from-palette($light-color-palette, $hue);
}

$color-config: mat.m2-get-color-config($light-theme);
$primary: map.get($color-config, primary);
$accent: map.get($color-config, accent);

:root {
	--primary-color: #{map.get($primary, 500)};
	--secondary-color: #{map.get($accent, 500)};
}
