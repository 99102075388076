@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($color-config);
  }
}

@mixin color($color-config) {
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);
  $background-palette: map.get($color-config, background);
  $background-color: map.get($background-palette, background);

  $primary-color: map-get($primary-palette, 500);
  $accent-color: map-get($accent-palette, 500);
  $carbon-fiber-base-shade: map-get($accent-palette, 400);
  $primary-light-color: map-get($primary-palette, A100);

  $carbon-fiber-shade-1: lighten($carbon-fiber-base-shade, 6%);
  $carbon-fiber-shade-2: lighten($carbon-fiber-base-shade, 4%);
  $carbon-fiber-shade-3: lighten($carbon-fiber-base-shade, 2%);
  $carbon-fiber-shade-4: $carbon-fiber-base-shade;
  $carbon-fiber-shade-5: darken($carbon-fiber-base-shade, 2%);
  $carbon-fiber-shade-6: darken($carbon-fiber-base-shade, 4%);
  $carbon-fiber-shade-7: darken($carbon-fiber-base-shade, 6%);

  :root {
    --sol-accent-color: #{$accent-color};
    --sol-background-color: #{$background-color};
    --sol-primary-light-color: #{$primary-light-color};
  }

  .sol-highlighted-search {
    background-color: yellow;
  }

  sol-grouped-accordion .mat-expansion-panel-body {
    background: var(--mat-app-background-color);
    padding: 0.5rem 1.5rem;
    border-top: solid 1px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  sol-grouped-accordion.outer-grouping .mat-expansion-panel-header.mat-expanded {
    height: 3rem;
  }
  sol-grouped-accordion.inner-grouping .mat-expansion-panel-body {
    background: var(--mat-app-background-color);
    padding: 0.25rem 0;
    border: none;
  }
  sol-grouped-accordion .mat-expansion-panel-header.mat-expanded {
    height: 3rem;
  }
  sol-grouped-accordion.inner-grouping mat-expansion-panel-header {
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
  sol-grouped-accordion.inner-grouping mat-expansion-panel {
    box-shadow: none !important;
  }
  sol-table .mat-mdc-row {
    height: auto;
  }

  sol-table table.mat-mdc-table tbody {
  }
}
