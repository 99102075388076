app-module-page {
	mat-expansion-panel .mat-expansion-panel-body {
		padding: 0;
	}
}
mat-chip-grid[sol-chip-grid].mdc-evolution-chip-set {
	.mdc-evolution-chip-set__chips {
		flex: 1;
	}
	.mdc-evolution-chip {
		width: 100%;
	}
}