.sol-hierarchy {
  &-image {
    background-repeat: no-repeat;
    width: 2em;
    height: 2em;
    margin-right: 0.5em;
    background-color: white;
    border-radius: 50%;
    padding: 0.25em;
  }
}

// Add theme styles here

.sol-hierarchy {
  &-vertical {
    flex-direction: column;
  }

  &-hierarchy-container {
    display: flex;

    &-vertical {
      flex-direction: column;
    }
  }

  &-connector {
    flex: 1;
    &-container {
      display: flex;
      &-horizontal {
        flex-direction: column;
      }
    }
  }

  &-section {
    display: flex;
    flex: 1;

    &-horizontal {
      flex-direction: column;
    }
  }
}


.sol-hierarchy {
  &-horizontal {
    padding: 1em 0;
  }

  &-vertical {
    padding: 0 1em;
  }

  &-border {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }

  &-box {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
      0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  }

  &-connector {
    &-horizontal {
      width: 1.3em;
    }

    &-vertical {
      height: 1.3em;
    }
  }
}

@mixin fx {
  display: flex;
}

@mixin fx-col {
  @include fx();
  flex-direction: column;
}
@mixin fx-col-fill($basis: auto) {
  @include fx-col();
  flex: 1 1 $basis;
}

@mixin fx-row {
  @include fx();
  flex-direction: row;
}
@mixin fx-row-fill($fill: auto) {
  @include fx-col();
  flex: 1 1 $fill;
}

.sol-fx {
  @include fx();
}
.sol-fx-row {
  @include fx-row();
}
.sol-fx-row-fill {
  @include fx-row-fill();
}

.sol-fx-col {
  @include fx-col();
}
.sol-fx-col-fill {
  @include fx-col-fill();
}
