@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($color-config);
  }
}

@mixin color($color-config) {
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);
  $background-palette: map.get($color-config, background);
  $background-color: map.get($background-palette, background);
  $background-light-contrast: mat.m2-get-contrast-color-from-palette($accent-palette, A700);
  // $background-light-contrast: mat.get-color-from-palette($primary-palette, 50);
  $primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
  $primary-lightest: mat.m2-get-color-from-palette($primary-palette, 50);
  $accent-color: mat.m2-get-color-from-palette($accent-palette, 500);
  $accent-lightest: mat.m2-get-color-from-palette($accent-palette, 50);

  :root {
    --sol-background-color: #{$background-color};
    --sol-background-contrast: #{$background-light-contrast};
    --sol-primary-color: #{$primary-color};
    --sol-primary-lightest: #{$primary-lightest};
    --sol-accent-color: #{$accent-color};
    --sol-accent-lightest: #{$accent-lightest};
  }

  .form-item {
    margin: 8px;
    mat-form-field {
      max-width: min(70vw, 20rem);
      width: 20rem;
    }
  }
  
}
