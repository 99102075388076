@use "@angular/material" as mat;

.sol-scroll-shadow-container {
  position: relative;
  box-sizing: border-box;
  overflow-y: auto;
  min-height: 0;
  max-height: 100%;

  &:before,
  &:after {
    @include mat.elevation(4);
    position: sticky;
    display: block;
    content: "";
    pointer-events: none;
    z-index: 1;
    opacity: 0;
    left: 0;
    right: 0;
    will-change: background-color, box-shadow, opacity;
    transition-property: background-color, box-shadow, opacity;
    transition-timing-function: cubic-bezier(
      0.4,
      0,
      0.2,
      1
    ); //Google Materials Easing;
    transition-duration: 250ms;
    background-color: transparent;
    height: 1px;
  }

  &:before {
    top: -1px;
    z-index: 102;
  }

  &:after {
    bottom: -1px;
    rotate: 180deg;
    z-index: 102;
  }

  &.sssc-overflow-top:before,
  &.sssc-overflow-bottom:after {
    @include mat.elevation(8);
    opacity: 1;
    background-color: var(--mat-divider-color, #0000001f);
  }
}
