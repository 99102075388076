@use 'sass:map';
@use 'sass:meta';
@use '@angular/material' as mat;

@mixin theme($theme) {
	$color-config: mat.m2-get-color-config($theme);

	@if $color-config != null {
		@include color($color-config);
	}
}

@mixin color($color-config) {
	$primary-palette: map.get($color-config, primary);

	.sol-login-page {
		--sol-login-page-primary-color: #{mat.m2-get-color-from-palette($primary-palette)};
	}
}
