@use '@angular/material' as mat;

@import '@soleran/ngx-node-editor';
@import '@soleran/ngx-workflow';

@import './module-styles.scss';
@import './report-builder-styles.scss';
@import './workflow-styles.scss';
@import './control-styles.scss';


:root {
	--scrollbar-size: 0.5rem;
	--animation-easing: cubic-bezier(0.4, 0, 0.2, 1); //Google Materials Easing
	--disabled-icon-opacity: 0.5;
	--disabled-text-color: #00000060;

	//Google Material
	--mat-sidenav-scrim-color: #00000020; //Backdrop Overlay
}

html,
body {
	height: 100%;
}
body {
	margin: 0;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.fx-col {
	display: flex;
	flex-direction: column;
}

.fx-row {
	display: flex;
	flex-direction: row;
}

.fx-fill {
	flex: 1 1 auto;
}
.fx-row.center {
	align-items: center;
}
.line-text {
	display: flex;
	justify-content: center;
	align-items: center;
	white-space: nowrap;
	gap: 0.25rem;
	width: 100%;

	&::before,
	&::after {
		content: '';
		display: block;
		border-top: solid var(--mat-divider-color, #0000001f) 1px;
		height: 1px;
		width: 50%;
	}
}

.mat-dense-1 {
	@include mat.all-component-densities(-1);
}
.mat-dense-2 {
	@include mat.all-component-densities(-2);
}
.mat-dense-3 {
	@include mat.all-component-densities(-3);
}

::-webkit-scrollbar {
	height: var(--scrollbar-size);
	width: var(--scrollbar-size);
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 0.25rem;
	border-style: solid;
	border-color: #0000000f;
	border-width: 1px;
}

::-webkit-scrollbar-thumb {
	border-radius: 0.25rem;
	background: rgba(0, 0, 0, 0.26);
}

//Material Overrides
html.cdk-global-scrollblock {
	overflow-y: hidden;
}

mat-tooltip-component {
	white-space: pre-line;
}

mat-toolbar {
	min-height: var(--mat-toolbar-standard-height);

	@media (max-width: 599px) {
		min-height: var(--mat-toolbar-mobile-height);
	}
}

// REPORTS

sol-report-table .mat-drawer-inner-container {
	display: flex;
	min-height: 0;
}
mat-form-field textarea {
	min-height: 1.5rem;
}
.sol-highlighted-search {
	background-color: yellow;
}
app-record-page mat-tab-group .mat-mdc-tab-body-wrapper {
	width: auto;
}
