@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($color-config);
  }
}

@mixin color($color-config) {
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);

  :root {
    --sol-file-upload-highlight-color: #{mat.m2-get-color-from-palette(
        $primary-palette,
        600
      )};
    --sol-primary-color: #{mat.m2-get-color-from-palette($primary-palette, 500)};
    --sol-accent-color: #{mat.m2-get-color-from-palette($accent-palette, 500)};
    --sol-primary-color-contrast: #{mat.m2-get-contrast-color-from-palette(
        $primary-palette,
        500
      )};
    --sol-background-light: #{mat.m2-get-contrast-color-from-palette(
        $primary-palette,
        900
      )};
  }

  .colored-panel {
    mat-expansion-panel-header[override] {
      background-color: var(--sol-primary-color, #4186cb) !important;
    }
    .field-options-header {
      color: var(--sol-primary-color-contrast, white) !important;
    }
  }
  ngx-colors[sol] div.preview {
    transform: scale(.8) translate(0, -8px);
  }
  sol-text-area .mat-mdc-text-field-wrapper {
    // padding-right: 8px;
  }
  sol-text-area .mat-mdc-form-field-flex,
  sol-text-area .mat-mdc-form-field-infix {
    height: 100%;
  }
  sol-options-bulk-add mat-form-field .mat-mdc-form-field-flex,
  sol-options-bulk-add mat-form-field .mat-mdc-form-field-infix {
    height: 100%;
  }
  sol-picture-upload file-upload label.upload-input {
    // display: flex !important;
    // position: static !important;
    // transform: none !important;
    height: 100%;
  }
  sol-text-area textarea[sol].textarea {
    height: 100%;
  }
  sol-formula-builder sol-editor[formula] {
    & div.se-outline {
      display: flex;
    }
    & div.se-outline-spacer {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    & slate-editable.slate-editable-container {
      flex: 1;
    }
  } 
  sol-auto-number-builder sol-editor[formula] {
    & div.se-outline {
      display: flex;
    }
    & div.se-outline-spacer {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    & slate-editable.slate-editable-container {
      flex: 1;
    }
  } 
  sol-user-avatar[owner] mat-icon{
    margin-right: 0 !important;
  }
}
