@use "sass:map";
@use "sass:meta";
@use "@angular/material" as mat;

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($color-config);
  }
}

@mixin color($color-config) {
  $primary-palette: map.get($color-config, primary);
  $accent-palette: map.get($color-config, accent);
  $background-palette: map.get($color-config, background);
  $background-color: map.get($background-palette, background);
  $background-light-contrast: mat.m2-get-contrast-color-from-palette($primary-palette, A700);
  // $background-light-contrast: mat.m2-get-color-from-palette($primary-palette, 50);
  $primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
  $primary-lightest: mat.m2-get-color-from-palette($primary-palette, 50);
  $primary-lighter: mat.m2-get-color-from-palette($primary-palette, 300);
  $accent-color: mat.m2-get-color-from-palette($accent-palette, 500);
  $accent-lightest: mat.m2-get-color-from-palette($accent-palette, 50);
  $accent-lighter: mat.m2-get-color-from-palette($accent-palette, 300);

  :root {
    --sol-background-color: #{$background-color};
    --sol-background-contrast: #{$background-light-contrast};
    --sol-primary-color: #{$primary-color};
    --sol-primary-lighter: #{$primary-lighter};
    --sol-primary-lightest: #{$primary-lightest};
    --sol-accent-color: #{$accent-color};
    --sol-accent-lightest: #{$accent-lightest};
    --sol-accent-lighter: #{$accent-lighter};
  }

  sol-content-tray {
    .mat-expansion-panel-header.mat-expanded {
      height: 3rem;
    }
    .mat-expansion-panel-body {
      border-top: solid 1px var(--mat-divider-color, #0000001F);
      // border-bottom: solid 1px var(--mat-divider-color, #0000001F);
    }
  }
  sol-page-builder mat-drawer .mat-drawer-inner-container {
    overflow: visible;
  }
  sol-tab-group-form-component mat-tab-group[sol] {
    flex: 1;
  }
  sol-tab-group-form-component mat-tab-group[sol] .mat-mdc-tab-body-wrapper,
  sol-tab-group-form-component mat-tab-group[sol] mat-tab-body.mat-mdc-tab-body-active,
  sol-tab-group-form-component mat-tab-group[sol] .mat-mdc-tab-body-content {
    overflow: visible;
  }
  sol-tab-group-form-component mat-tab-group[design].mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs > .mat-mdc-tab-header .mat-mdc-tab:nth-last-of-type(-n+2) {
    flex: 0;
    padding: 0;
    min-width: 3rem;
  }
  
}
