@use '@angular/material' as mat;
.sol-workflow-list {
	margin: 3rem auto;
	width: 1000px;
	@media screen and (max-width: 1000px) {
		width: 100%;
	}
}
sol-workflow-builder {
	.sol-fx-col-fill {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
	}
	sol-workflow-nav {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		mat-sidenav-container {
			flex: 1 1 auto;
		}
	}
	sol-node-descriptor {
		display: flex;
	}
	sol-builder-form {
		sol-group-form {
			@include mat.elevation(0);
			background: none;
			border: 1px solid rgba(0, 0, 0, 0.3);
		}
	}
	.mat-expansion-panel,
	.mat-expansion-panel:not([class*='mat-elevation-z']) {
		@include mat.elevation(0);
		.mat-expansion-panel-body {
			padding: 0;
		}
	}
	sol-group-form {
		sol-expression-form mat-form-field,
		sol-input mat-form-field {
			@include mat.form-field-density(-3);
			max-width: 120px !important;
		}
	}
}
